import React, { useState } from "react"
import Logo from "../../assets/LOGO.png"
import "./header_styles.css"

import {
  HeaderWrapper,
  Links,
  LinkWrapper,
  LogoWrapper,
  SubActionButton,
  LogoImg,
  NotLink,
  HeaderActionButton,
  ScrollLinks3,
  ActionButton0,
} from "../UI/PublicUI"
import styled from "styled-components"

const LINKS = [
  {
    id: 1,
    link: "Products",
  },
  {
    id: 2,
    link: "Prospect",
    url: "/prospect",
  },
  {
    id: 3,
    link: "Analysis",
    url: "/analysis",
  },
  {
    id: 4,
    link: "About",
    url: "/about",
  },
  {
    id: 5,
    link: "Team",
    url: "/team",
  },
]

const OutLinks = [
  {
    id: 1,
    link: "CBDC by Polytope Networks (Mirror 1)",
    url: "https://cbdc.polytopenetworks.com/",
    working: false,
  },
  {
    id: 1,
    link: "CBDC by Polytope Networks (Mirror 2)",
    url: "http://164.90.164.8:3000/",
    working: true,
  },
  {
    id: 2,
    link: "POLYVRTX NFT",
    url: "https://nft.polyvrtx.com",
    working: true,
  },
  {
    id: 3,
    link: "GRAVITONET",
    url: "https://gravitonet.io/",
    working: true,
  },
]

const Header = () => {
  const [actionModal, setActionModal] = useState(false)

  const handleActionModal = () => {
    setActionModal(!actionModal)
  }

  const ActionModalContainer = styled.div`
    z-index: 10000;
    position: absolute;
    top: 20vh;
    left: 20vw;
    width: 60vw;
    height: auto;
    padding: 2rem;
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.7);
    display: flex;
    flex-flow: column;
    justify-content: center;
  `

  const ActionModalContentHorizontal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem 0;
  `

  const ActionModalContentVertical = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  `

  const ActionModalHeader = styled.h1`
    align-self: flex-start;
    color: black;
    font-family: Poppins;
    font-size: 1.2rem;
    text-transform: initial;
    font-weight: 500;
    line-height: 4rem;
  `

  const ActionModalDetails = styled.p`
    font-family: Poppins;
    font-size: 0.8rem;
    text-transform: initial;
    font-weight: 400;
    color: black;
  `

  return (
    <HeaderWrapper>
      <LogoWrapper to="/">
        <LogoImg src={Logo} alt="" />
        <span>POLYTOPE NETWORKS</span>
      </LogoWrapper>

      <LinkWrapper>
        {LINKS.map((item) => (
          <>
            {item.url ? (
              <Links key={item.id} to={item.url}>
                {item.link}
              </Links>
            ) : (
              <div className="dropsection">
                <NotLink key={item.id}>{item.link}</NotLink>
                <div className="dropdown">
                  {OutLinks.map((item) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "0.6rem 1rem",
                        }}
                      >
                        <div
                          style={{
                            background: item.working
                              ? "rgb(172, 226, 0)"
                              : "red",
                            height: "0.4rem",
                            width: "0.4rem",
                            marginRight: "0.4rem",
                            borderRadius: "1rem",
                          }}
                        />
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={item.url}
                          key={item.id}
                        >
                          {item.link}
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </>
        ))}
      </LinkWrapper>

      <HeaderActionButton onClick={() => handleActionModal()}>
        CBDC
      </HeaderActionButton>

      {actionModal ? (
        <ActionModalContainer className="action-modal">
          <ActionModalContentHorizontal>
            <ActionModalContentVertical>
              <ActionModalHeader>Testing Credentials</ActionModalHeader>
              <ActionModalDetails>Currently using Mirror 2</ActionModalDetails>
            </ActionModalContentVertical>

            <ActionModalContentVertical>
              <ActionModalDetails>
                Credential 1
                <br />
                <br />
                <li>"hostName": "localhost",</li>
                <li>"password": "test",</li>
                <li>"port": "10012",</li>
                <li>"username": "CentralBank"</li>
                <br />
                Credential 2
                <br />
                <br />
                <li>"hostName": "localhost",</li>
                <li>"password": "test",</li>
                <li>"port": "10009",</li>
                <li>"username": "MerchantPayment"</li>
                <br />
                Credential 3
                <br />
                <br />
                <li>"hostName": "localhost",</li>
                <li>"password": "test",</li>
                <li>"port": "10006",</li>
                <li>"username": "ReserveBank"</li>
              </ActionModalDetails>
            </ActionModalContentVertical>
          </ActionModalContentHorizontal>

          <ActionModalContentHorizontal>
            <ActionButton0
              style={{
                background: "red",
              }}
              onClick={() => handleActionModal()}
            >
              Close
            </ActionButton0>
            <ScrollLinks3
              target="_blank"
              rel="noreferrer"
              href="http://164.90.164.8:3000/"
            >
              Go To CBDC
            </ScrollLinks3>
          </ActionModalContentHorizontal>
          <ActionModalContentHorizontal>
            <ActionModalHeader>Ask for a demo.</ActionModalHeader>
          </ActionModalContentHorizontal>
        </ActionModalContainer>
      ) : null}
    </HeaderWrapper>
  )
}

export default Header
