// import Helmet from "react-helmet"
import { Router, Route, Switch } from "react-router-dom"

// Utils
import history from "./utils/history"

// Page Components
import Layout from "./components/Layouts/Layout"
import Home from "./pages/home/Home"
import Prospect from "./pages/prospect/Prospect"
import About from "./pages/about/About"
import Analysis from "./pages/analysis/Analysis"
import Team from "./pages/team/Team"

const App = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Layout>
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/portfolio" component={Solutions} /> */}
            <Route exact path="/prospect" component={Prospect} />
            <Route exact path="/analysis" component={Analysis} />
            <Route exact path="/about" component={About} />
            <Route exact path="/team" component={Team} />
          </Layout>
        </Switch>
      </Router>
    </>
  )
}

export default App
