import styled, { keyframes } from "styled-components"
import { Link } from "react-router-dom"
import { Link as ScrollLink } from "react-scroll"

export const BannerTitleText = styled.h1`
  align-self: ${(props) =>
    props.center ? "center" : props.right ? "flex-end" : "flex-start"};
    color: ${(props) =>
      props.background
        ? "rgba(var(--color-blk),1)"
        : "rgba(var(--color-wht),1)"};
    font-family: Poppins;
    font-size: 3.4rem;
    text-transform: initial;
    font-weight: 500;
    line-height: 4rem;
    margin: 0% 0% 3% 0%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    align-self: ${(props) =>
      props.center ? "center" : props.right ? "flex-end" : "flex-start"};
    color: ${(props) =>
      props.background
        ? "rgba(var(--color-blk),1)"
        : "rgba(var(--color-wht),1)"};
    font-family: Poppins;
    font-size: 2rem;
    text-transform: initial;
    font-weight: 700;
    line-height: 4rem;
    margin: 0% 0% 3% 0%;

    font-size: 2rem;
    line-height: 2.5rem;
`

export const LogoImg = styled.img`
  width: 2rem;
  height: auto;
  object-fit: cover;
  margin-right: 0.5rem;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 8vw;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  padding: 0% 10% 0% 2%;
  width: 100%;
  top: 0%;
  left: 0%;
  position: fixed;
  height: 12%;
  background: black;

  ${
    "" /* box-shadow: 0px 2px 8px 0px rgba(var(--color-blk), 0.08);
  border-bottom: 1px solid rgba(var(--color-wht), 0.2);
  background-color: rgba(var(--color-pri), 0.7);
  backdrop-filter: blur(7px); */
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    box-shadow: none;
    border: none;
    height: 10vh;
    padding: 3% 7%;
    background-color: black;
    mix-blend-mode: unset;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: auto;
    padding: 5% 7%;
  }
`

export const LogoWrapper = styled(Link)`
  text-decoration: none;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1.2rem;
  text-transform: capitalize;
  letter-spacing: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  // font-weight: 500;
  // background: linear-gradient(
  //   270deg,
  //   #ffe580 -21.36%,
  //   #ff7571 -2.45%,
  //   #ea5dad 26.84%,
  //   #0000ff 64.15%,
  //   #303030 108.29%,
  //   #b2f4b6 159.03%
  // );
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 1rem;
    font-weight: 300;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;

  @media ${({ theme }) => theme.mediaQueries.small} {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 2vh;
    height: auto;
  }
`

export const ScrollLinks = styled(ScrollLink)`
  color: rgba(var(--color-blk), 0.7);
  font-size: 0.8rem;
  font-family: "Poppins";
  text-transform: capitalize;
  font-weight: 400;
  text-decoration: none;
  transition: all var(--speed);
  cursor: pointer;

  &:hover {
    color: rgba(var(--color-blk), 1);
    box-shadow: 0 5px 10px -5px rgba(var(--color-blk), 0.3);
    transform: translateY(-3px);
  }

  &:focus {
    color: rgba(var(--color-blk), 1);
    box-shadow: 0 5px 10px -5px rgba(var(--color-blk), 0.3);
    transform: translateY(-3px);
  }
`

export const Links = styled(Link)`
  color: rgba(var(--color-wht), 0.7);
  font-size: 1rem;
  font-family: "Poppins";
  text-transform: capitalize;
  font-weight: 400;
  text-decoration: none;
  transition: all var(--speed);
  cursor: pointer;
  padding-right: 1rem;

  transition: all var(--speed);

  &:hover {
    color: rgba(var(--color-wht), 1);
    transform: translateY(-3px);
  }

  &:focus {
    color: rgba(var(--color-wht), 1);
    transform: translateY(-3px);
  }
`
export const NotLink = styled.p`
  margin: 0;
  color: rgba(var(--color-wht), 0.7);
  font-size: 1rem;
  font-family: "Poppins";
  text-transform: capitalize;
  font-weight: 400;
  text-decoration: none;
  transition: all var(--speed);
  cursor: pointer;
  padding-right: 1rem;

  transition: all var(--speed);

  &:hover {
    color: rgba(var(--color-wht), 1);
    transform: translateY(-3px);
  }

  &:focus {
    color: rgba(var(--color-wht), 1);
    transform: translateY(-3px);
  }
`

export const ColoredMarker = styled.span`
  color: #ff7571;
  font-family: Poppins;
  text-transform: initial;
  line-height: 2.8rem;

  font-size: 2.4rem;
  font-weight: 500;

  // background: linear-gradient(
  //   170deg,
  //   #ffe580 -21.36%,
  //   #ff7571 -2.45%,
  //   #ea5dad 26.84%,
  //   #47b9ff 64.15%,
  //   #0000ff 108.29%,
  //   #b2f4b6 159.03%
  // );
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  @media ${({ theme }) => theme.mediaQueries.small} {
    align-self: flex-start;
    color: rgba(var(--color-wht), 1);
    font-family: Poppins;
    text-transform: initial;
    font-weight: 700;
    margin: 0% 0% 3% 0%;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
`

export const LightMarker = styled.h1`
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  text-transform: initial;
  line-height: 2.8rem;
  font-size: 2.4rem;
  font-weight: 700;
`

export const LightMarker2 = styled.h1`
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  text-transform: initial;
  line-height: 1.8rem;
  font-size: 1.8rem;
  font-weight: 400;
`

export const NormalMarker = styled.span`
  font-weight: 500;
`

export const NotImpMarker = styled.span`
  font-weight: 600;
  background: rgba(var(--color-strk), 0.75);
  color: white;
  cursor: pointer;
`

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 120vh;
  display: flex;
  justify-content: ${(props) =>
    props.start
      ? "flex-start"
      : props.end
      ? "flex-end"
      : props.between
      ? "space-between"
      : "center"};
  background-color: rgba(var(--color-blk), 1);
  clip-path: polygon(100% 0, 0 0, 0 100%, 100% 95.5%);
  padding: 10% 17%;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    padding: 20% 10% 20%;
    height: 105vh;

    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 98%);
  }
`

export const IntroSectionWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
  z-index: 4;

  margin-top: 10%;

  // background-color: rgba(var(--color-strk), 0.1);
  // backdrop-filter: blur(2px);

  // width: 50%;
  // position: absolute;
  /* padding: 2rem; */
  width: 55%;
  /* left: 18%; */

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 10% 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
`

export const Header = styled.h1`
  color: rgba(var(--color-pri), 1);
  font-family: Poppins;
  font-size: 4rem;
  text-transform: capitalize;
  font-weight: 700;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 3.4rem;
    font-weight: 500;
  }
`

export const SubHeader = styled.h1`
  align-self: ${(props) =>
    props.center ? "center" : props.right ? "flex-end" : "flex-start"};
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1.2rem;
  text-transform: initial;
  font-weight: 400;
  line-height: 1.6rem;
  margin: 0% 0% 3% 0%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    align-self: flex-start;
    color: rgba(var(--color-wht), 1);
    font-family: Poppins;
    text-transform: initial;
    font-weight: 400;
    margin: 0% 0% 0% 0%;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`

export const SubHeaderFooter = styled.h1`
  font-size: 3.4rem;
  font-weight: 500;
  font-family: Poppins;
  margin: 0%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 2rem;
    font-weight: 800;
    font-family: Poppins;
    margin: 0%;
    line-height: 2rem;
  }
`

export const SubHeaderFooter2 = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Poppins;
  margin: 0%;
  margin-left: 2%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 1rem;
    font-weight: 400;
    font-family: Poppins;
    margin: 0%;
    margin-left: 2%;
  }
`

export const SubHeader2 = styled.h1`
  align-self: ${(props) =>
    props.center ? "center" : props.right ? "flex-end" : "flex-start"};
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1.4rem;
  text-transform: initial;
  font-weight: 600;
  line-height: 2rem;
  margin: 0% 0% 2% 0%;
  color: #ff6a92;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 500;
    margin: 0% 0% 1% 0%;
  }
`

export const NotImpSubHeader = styled.h2`
  color: ${(props) =>
    props.background
      ? "rgba(var(--color-blk),1)"
      : "rgba(var(--color-wht),0.7)"};
  font-family: Poppins;
  font-size: 1rem;
  text-transform: initial;
  font-weight: 500;
  line-height: 1.6rem;
  margin: ${(props) => (props.center ? "0% 17% 4%" : "0% 0% 6% 0%")};

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 400;
    margin: 0% 0% 5% 0%;
    width: 90%;
  }
`

export const NotImpSubHeader0 = styled.a`
  text-decoration: none;
  color: ${(props) =>
    props.background
      ? "rgba(var(--color-blk),1)"
      : "rgba(var(--color-wht),0.7)"};
  font-family: Poppins;
  font-size: 1rem;
  text-transform: initial;
  font-weight: 500;
  line-height: 1.6rem;
  margin: ${(props) => (props.center ? "0% 17% 4%" : "0% 0% 6% 0%")};

  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.2rem;
  margin: 0% 0% 4% 0%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 400;
    margin: 0% 0% 5% 0%;
    width: 72%;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0% 0%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
  }
`

export const AuthCard = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 2rem;
  // box-shadow: 0 8px 8px -4px rgba(var(--color-blk), 0.2);
  // border: 1px solid rgba(var(--color-blk), 0.07);
  background-color: transparent;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.small} {
    padding: 0 15vw 5%;
  }
`

export const ScrollLinks2 = styled(ScrollLink)`
  cursor: pointer;
  padding: 1.1rem;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid rgba(var(--color-pri), 1);
  background: rgba(var(--color-pri), 1);
  box-shadow: 0 10px 20px -10px rgba(var(--color-wht), 0.3);
  transition: all var(--speed);
  width: auto;
  height: auto;
  text-decoration: none;

  &:hover {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: rgba(var(--color-pri), 1);
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  &:focus {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: rgba(var(--color-pri), 1);
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-right: 2rem;
    font-size: 0.8rem;
    padding: 0.6rem;
  }
`

export const ScrollLinks3 = styled.a`
  cursor: pointer;
  padding: 1.1rem;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid rgba(var(--color-pri), 1);
  background: rgba(var(--color-pri), 1);
  box-shadow: 0 10px 20px -10px rgba(var(--color-wht), 0.3);
  transition: all var(--speed);
  width: auto;
  height: auto;
  text-decoration: none;

  &:hover {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: rgba(var(--color-pri), 1);
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  &:focus {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: rgba(var(--color-pri), 1);
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-right: 2rem;
    font-size: 0.8rem;
    padding: 0.6rem;
  }
`

export const ActionButton = styled(Link)`
  padding: 1.1rem;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid rgba(var(--color-pri), 1);
  background: rgba(var(--color-pri), 1);
  box-shadow: 0 10px 20px -10px rgba(var(--color-wht), 0.3);
  transition: all var(--speed);
  width: auto;
  height: auto;
  text-decoration: none;

  &:hover {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: rgba(var(--color-pri), 1);
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  &:focus {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: rgba(var(--color-pri), 1);
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-right: 2rem;
    font-size: 0.8rem;
    padding: 0.6rem;
  }
`

export const HeaderActionButton = styled.button`
  cursor: pointer;
  color: rgba(var(--color-pri), 1);
  font-family: Poppins;
  text-transform: capitalize;
  font-weight: 700;
  border: 1px solid rgba(var(--color-wht), 1);
  background: rgba(var(--color-wht), 1);
  box-shadow: 0 10px 20px -10px rgba(var(--color-pri), 0.3);
  transition: all var(--speed);
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0.8rem 1.8rem;

  &:hover {
    border: 1px solid rgba(var(--color-wht), 1);
    background: rgba(var(--color-wht), 1);
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  &:focus {
    border: 1px solid rgba(var(--color-wht), 1);
    background: rgba(var(--color-wht), 1);
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-right: 2rem;
    font-size: 0.8rem;
    padding: 0.6rem;
  }
`

export const SecActionButton = styled(Link)`
  padding: 1.38vh 1.38vw;
  color: rgba(var(--color-strk), 1);
  font-family: Poppins;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 500;
  // border-radius: 0.5rem;
  border: 1px solid rgba(var(--color-strk), 1);
  background: rgba(var(--color-wht), 1);
  /* box-shadow: 0 10px 20px -10px rgba(var(--color-strk),0.5); */
  /* -webkit-transition: all var(--speed); */
  transition: all var(--speed);
  width: auto;
  height: auto;
  text-decoration: none;

  &:hover {
    background: #ff7571;
    color: rgba(var(--color-wht), 1);
    box-shadow: 0 20px 20px -10px rgba(var(--color-strk), 0.5);
    /* -webkit-transform: translateY(-5px); */
    -ms-transform: translateY(-5px);
    transform: translateY(-2px);
  }

  &:focus {
    background: #ff7571;
    color: rgba(var(--color-wht), 1);
    box-shadow: 0 20px 20px -10px rgba(var(--color-strk), 0.5);
    /* -webkit-transform: translateY(-5px); */
    -ms-transform: translateY(-5px);
    transform: translateY(-2px);
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 0.7rem;
    font-weight: 400;
    padding: 1.8vh 5vw;
  }
`

export const CardWrapper = styled.div`
  width: 100%;
  heiht: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 5% 0%;
`

export const SubCardWrapper1 = styled.div`
  width: 100%;
  heiht: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.small} {
    flex-direction: column;
  }
`

export const CardWrapperTextSection = styled.div`
  width: 100%;
  heiht: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 10% 17%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    padding: 24% 10%;
  }
`

export const Card = styled.div`
  height: 34vh;
  width: 26%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(var(--color-blk), 1);
  box-shadow: 0 8px 8px -4px rgba(var(--color-blk), 0.2);
  // border: 1px solid rgba(var(--color-blk), 0.4);
  // border-radius: 1rem;
  transition: all var(--speed);
  padding: 2%;
  border: 1px solid rgba(var(--color-blk), 0.07);
  background-color: rgba(var(--color-wht), 1);

  &:hover {
    box-shadow: 0 20px 20px -10px rgba(var(--color-blk), 0.3);
    transform: translateY(-7px);
  }

  // &:focus {
  //   box-shadow: 0 20px 20px -10px rgba(var(--color-blk), 0.3);
  //   transform: translateY(-7px);
  // }

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    margin-bottom: 10%;
  }
`

export const FeatureCard = styled.div`
  height: ${(props) => (props.column ? "auto" : props.large ? "auto" : "50%")};
  width: ${(props) => (props.column ? "90%" : props.large ? "23%" : "17%")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(var(--color-blk), 1);
  box-shadow: 0 8px 8px -4px rgba(var(--color-blk), 0.2);
  transition: all var(--speed);
  padding: 2%;
  border: 1px solid rgba(var(--color-blk), 0.07);
  background-color: rgba(var(--color-wht),1);

  height: inherit;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(var(--color-blk),1);
    box-shadow: 0 8px 8px -4px rgba(var(--color-blk),0.2);
    -webkit-transition: all var(--speed);
    transition: all var(--speed);
    padding: 1rem;
    border: 1px solid rgba(var(--color-blk),0.07);
    background-color: rgba(var(--color-wht),1);

    /* background: rgba(129,52,175,0.4);
    border: 2px solid rgba(129,52,175,0.6); */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    padding: 18% 0%;

    background-color: transparent;
    background: transparent;

 /* &:hover {
    box-shadow: 0 20px 20px -10px rgba(var(--color-blk), 0.3);
    transform: translateY(-7px);
  } */

  // &:focus {
  //   box-shadow: 0 20px 20px -10px rgba(var(--color-blk), 0.3);
  //   transform: translateY(-7px);
  }

  
  @media ${({ theme }) => theme.mediaQueries.small} {
    padding: 10% 0%;
    margin: 0;
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }
`

export const FeatureCardRow = styled.div`
  height: ${(props) => (props.column ? "auto" : props.large ? "auto" : "50%")};
  width: ${(props) => (props.column ? "90%" : props.large ? "23%" : "17%")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(var(--color-blk), 1);
  box-shadow: 0 8px 8px -4px rgba(var(--color-blk), 0.2);
  transition: all var(--speed);
  padding: 2%;
  border: 1px solid rgba(var(--color-blk), 0.07);
  background-color: rgba(var(--color-wht),1);

  height: inherit;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(var(--color-blk),1);
    box-shadow: 0 8px 8px -4px rgba(var(--color-blk),0.2);
    -webkit-transition: all var(--speed);
    transition: all var(--speed);
    padding: 1rem;
    border: 1px solid rgba(var(--color-blk),0.07);
    background-color: rgba(var(--color-wht),1);

    /* background: rgba(129,52,175,0.4);
    border: 2px solid rgba(129,52,175,0.6); */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    padding: 5% 0%;

    background-color: transparent;
    background: transparent;

    width: 100%;
    margin: 0;
    padding-bottom: 0;
    flex-direction: column-reverse;

 /* &:hover {
    box-shadow: 0 20px 20px -10px rgba(var(--color-blk), 0.3);
    transform: translateY(-7px);
  } */

  // &:focus {
  //   box-shadow: 0 20px 20px -10px rgba(var(--color-blk), 0.3);
  //   transform: translateY(-7px);
  }

  
  @media ${({ theme }) => theme.mediaQueries.small} {
    padding: 10% 0%;
    margin: 0;
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }
`

export const FeatureCardGrid = styled.div`
  height: ${(props) => (props.column ? "auto" : props.large ? "auto" : "50%")};
  width: ${(props) => (props.column ? "90%" : props.large ? "23%" : "17%")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(var(--color-blk), 1);
  box-shadow: 0 8px 8px -4px rgba(var(--color-blk), 0.2);
  transition: all var(--speed);
  padding: 2%;
  border: 1px solid rgba(var(--color-blk), 0.07);
  background-color: rgba(var(--color-wht),1);

  height: inherit;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(var(--color-blk),1);
    box-shadow: 0 8px 8px -4px rgba(var(--color-blk),0.2);
    -webkit-transition: all var(--speed);
    transition: all var(--speed);
    padding: 1rem;
    border: 1px solid rgba(var(--color-blk),0.07);
    background-color: rgba(var(--color-wht),1);

    /* background: rgba(129,52,175,0.4);
    border: 2px solid rgba(129,52,175,0.6); */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    padding: 5% 0%;

    background-color: transparent;
    background: transparent;

    flex-direction: row-reverse;
    padding: 5% 0 7%;

 /* &:hover {
    box-shadow: 0 20px 20px -10px rgba(var(--color-blk), 0.3);
    transform: translateY(-7px);
  } */

  // &:focus {
  //   box-shadow: 0 20px 20px -10px rgba(var(--color-blk), 0.3);
  //   transform: translateY(-7px);
  }

  
  @media ${({ theme }) => theme.mediaQueries.small} {
    padding: 10% 0%;
    margin: 0;
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }
`

export const FeatureCardIcon = styled.img`
  width: 100%;
  height: 40vh;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    height: 20vh;
    object-fit: cover;
  }
`

export const FeatureCardIconRow = styled.img`
  width: 100%;
  height: 40vh;
  object-fit: cover;

  height: 50vh;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    height: 20vh;
    object-fit: cover;
  }
`

export const NotImpSubHeader02 = styled.p`
  // color: rgba(var(--color-wht), 0.7);
  // font-family: Poppins;
  // font-size: 0.8rem;
  // text-transform: initial;
  // font-weight: 300;
  // line-height: 1.4rem;
  // margin: 4% 0% 4% 0%;

  aalign-self: flex-start;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1rem;
  text-transform: initial;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 2% 0% 2% 0%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 0.7rem;
    text-transform: initial;
    line-height: 1rem;
  }
`

export const NotImpSubHeader2 = styled.p`
  align-self: flex-start;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 0.8rem;
  text-transform: initial;
  font-weight: 400;
  line-height: 1.4rem;
  margin: 0% 0% 2% 4%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 0.7rem;
    text-transform: initial;
    line-height: 1rem;
  }
`

const flowAnim = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const GetStartedCard = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  align-items: flex-start;
  width: auto;

  @media ${({ theme }) => theme.mediaQueries.small} {
  }
`

export const CardHeader = styled.div`
  width: 100%;
  min-height: 35%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const CardIcon = styled.img`
  width: 22%;
  height: auto;
  object-fit: cover;
  // background: rgba(var(--color-blk), 0.1);
  // border-radius: 50%;
  padding: 2%;
`

export const CardTitleWrapper = styled.div`
  width: -webkit-fill-available;
  padding: 0%;
  padding-left: 0.7rem;
  margin-top: 0.4rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const CardTitle = styled.h1`
  font-size: 1rem;
  font-family: "Poppins";
  font-weight: 400;
  word-break: break-word;
  line-height: 1rem;
  margin-bottom: 0.7rem;
`

export const CardSubText = styled.h2`
  font-family: "Poppins";
  font-weight: 300;
  opacity: 0.5;
  font-size: 0.7rem;
  letter-spacing: 0.02rem;
  line-height: 0.8rem;
  word-break: break-word;
`

export const CardDescWrapper = styled.div`
  width: 100%;
  min-height: 65%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0% 4%;
`

export const CardCost = styled.h1`
  color: rgba(var(--color-blk), 1);
  font-family: JetBrains Mono;
  font-size: 1.2rem;
  text-transform: initial;
  font-weight: 700;
  margin: 0% 0% 1% 0%;
  word-break: break-word;
  line-height: 2rem;
`

export const CostChangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
`

export const CardLastChange = styled.p`
  font-family: "Poppins";
  font-weight: 600;
  opacity: 0.5;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  line-height: 0rem;
  color: #007808;
  padding: 0.6rem 0.5rem;
  margin-right: 0.7rem;
`

export const CardLastChangeValue = styled.p`
  font-family: "Poppins";
  font-weight: 600;
  opacity: 0.5;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  line-height: 0rem;
  color: #a60008;
  padding: 0.6rem 0.2rem;
`

export const CardLastCheck = styled.p`
  font-family: "Poppins";
  font-weight: 300;
  opacity: 0.5;
  font-size: 0.75rem;
  letter-spacing: 0.02rem;
  line-height: 0.8rem;
  margin-left: 0.7rem;
`

export const BannerImageWrapper = styled.div`
  z-index: -1;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50%;
  height: 100%;
  right: 7%;
  top: 4%;
  opacity: 0.6;

  position: absolute;
  width: 83%;
  right: 5%;
  top: 0%;
  height: 100%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    height: 100%;
    right: 0%;
    top: -20%;
    opacity: 1;
  }
`

export const BannerImageWrapperMain = styled.div`
  z-index: -1;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.6;

  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    height: 100%;
    right: 0%;
    top: -20%;
    opacity: 1;

    width: auto;
    height: 100%;
    left: -170%;
    top: -10%;
  }
`

export const BannerSecImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const BannerImageWrapper2 = styled.div`
  z-index: 2;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50%;
  height: 100%;
  right: -1%;
  top: 5%;
`

export const SectionWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  background-color: rgba(var(--color-blk), 1);
  clip-path: polygon(100% 0%, 10% 5%, 10% 100%, 100% 95%);
  padding: 10% 17%;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    flex-direction: column;
    padding: 0% 10% 20%;

    clip-path: polygon(100% 0%, 2% 2%, 2% 100%, 100% 98%);
  }
`

export const SectionWrapper5 = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  background-color: rgba(var(--color-blk), 1);
  clip-path: polygon(100% 0%, 5% 2%, 5% 100%, 100% 98%);
  padding: 10% 17%;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    flex-direction: column;
    padding: 20% 10% 20%;

    clip-path: polygon(100% 0%, 2% 2%, 2% 100%, 100% 98%);
  }
`

export const SectionWrapper6 = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  background-color: rgba(var(--color-blk), 1);
  clip-path: polygon(100% 0%, 5% 1%, 5% 100%, 100% 99%);
  padding: 10% 17%;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    flex-direction: column;
    padding: 20% 10% 20%;

    clip-path: polygon(100% 0%, 2% 1%, 2% 100%, 100% 99%);
  }
`

export const SectionWrapper1 = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  background-color: rgba(var(--color-blk), 1);
  clip-path: polygon(95% 0%, 0% 5%, 0% 100%, 95% 95%);
  padding: 10% 17%;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    flex-direction: column;
    padding: 20% 10% 20%;

    clip-path: polygon(98% 0%, 0% 3%, 0% 100%, 98% 98%);
  }
`

export const SectionWrapper2 = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  background-color: rgba(var(--color-blk), 1);
  clip-path: polygon(95% 0%, 0% 5%, 0% 100%, 95% 95%);
  padding: 10% 17%;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    flex-direction: column;
    padding: 10% 0%;
  }
`

export const FooterWrapper = styled.div`
  width: 100%;
  // height: auto;
  height: 85vh;
  display: flex;
  justify-content: flex-start;
  background-color: rgba(var(--color-blk), 1);
  // clip-path: polygon(95% 0%, 0% 5%, 0% 100%, 95% 95%);
  padding: 10% 17%;
  align-items: flex-end;

  display: grid;
  /* grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 3fr; */
  grid-template-columns: 1fr 1fr;
  /* grid-template-columns: 6fr 2fr 1fr; */
  grid-template-rows: 1fr;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.small} {
    flex-direction: column;
    padding: 20% 10% 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
  }
`

export const SectionImageWrapper = styled.div`
  width: 45%;
  height: ${(props) => (props.between ? "auto" : "100%")};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.start
      ? "flex-start"
      : props.end
      ? "flex-end"
      : props.between
      ? "space-between"
      : "center"};
  align-items: flex-end;

  // border-right: ${(props) =>
    props.right ? "1px dashed rgba(var(--color-strk),1)" : "none"};
  opacity: 0.6;

  @media ${({ theme }) => theme.mediaQueries.small} {
    align-items: center;
    border: none;

    width: 100%;
    height: 40vh;
    padding: 0%;
    opacity: 1;
  }
`

export const ContentWrapper = styled.div`
  width: ${(props) => (props.large ? "100%" : props.small ? "28%" : "55%")};
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.center ? "center" : "flex-start")};

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
  }
`

export const CardsRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: auto;

  display: grid;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-row-gap: 1%;

  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;

  @media ${({ theme }) => theme.mediaQueries.small} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export const CardsRowGrid = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: auto;

  display: grid;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr;
  grid-row-gap: 1%;

  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export const Title = styled.h2`
  color: rgba(var(--color-blk), 1);
  font-family: Poppins;
  font-size: 1.4rem;
  text-transform: initial;
  font-weight: 400;
  line-height: 1.8rem;
  margin: 0% 0% 2% 0%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 400;
    margin: 0% 0% 2% 0%;
  }
`

export const Desc = styled.h2`
  color: rgba(var(--color-blk), 0.7);
  font-family: Poppins;
  font-size: 1rem;
  text-transform: initial;
  font-weight: 400;
  line-height: 1.8rem;
  margin: 0% 0% 1% 0%;

  margin-bottom: 1.5rem;
`

export const SubText = styled.h2`
  color: rgba(var(--color-blk), 0.5);
  font-family: Poppins;
  font-size: 0.8rem;
  text-transform: initial;
  font-weight: 300;
  line-height: 1.4rem;
  margin: 0% 0% 1% 0%;

  margin-bottom: 2.5rem;
`

export const SubActionButton = styled(Link)`
  font-size: 0.8rem;
  font-weight: 500;
  width: auto;
  height: auto;
  padding: 1vh 0.6vw;
  color: ${(props) =>
    props.outlined ? "rgba(var(--color-pri), 1)" : "rgba(var(--color-wht),1)"};
  font-family: Poppins;
  text-transform: capitalize;
  // border-radius: 0.3rem;
  border: ${(props) =>
    props.outlined
      ? "1px solid rgba(var(--color-pri), 1)"
      : "1px solid rgba(var(--color-strk),1)"};
  background: ${(props) =>
    props.outlined
      ? "rgba(var(--color-wht), 1)"
      : "rgba(var(--color-strk),0.4)"};
  transition: all var(--speed);
  text-decoration: none;

  &:hover {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: #ff7571;
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-3px);
  }

  &:focus {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: #ff7571;
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-3px);
  }
`

export const SubCardWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  flex-direction: column;
`

export const GetStartedWrapper = styled.div`
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
  }
`

export const FooterSection1 = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    display: none;
  }
`

export const FooterSection11 = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

export const FooterLogo = styled.p`
  text-decoration: none;
  color: rgba(var(--color-blk), 1);
  font-family: "Poppins";
  font-family: Poppins;
  font-size: 1.4rem;
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

export const FooterSectionSubText = styled.p`
  color: rgba(var(--color-blk), 0.7);
  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.8rem;
`

export const FooterSection12 = styled.div`
  width: 100%;
  height: 24%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const FooterSection2 = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    padding: 0% 10%;
  }
`
export const FooterSection21 = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    height: auto;
  }
`
export const FooterSection211 = styled.div`
  width: 45%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 50%;
  }
`
export const FooterSection212 = styled.div`
  width: 45%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
export const FooterSection22 = styled.div`
  width: 100%;
  height: 24%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.small} {
  }
`

export const FooterSection3 = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.small} {
    display: none;
  }
`

export const FooterSection31 = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
`

export const FooterSection32 = styled.div`
  width: 100%;
  height: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export const InputStyled = styled.input`
  width: 100%;
  height: 3rem;
  border: 1px solid rgba(var(--color-blk), 0.2);
  font-size: 1rem;
  padding: 0rem 1rem;
  margin-bottom: 4%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    height: 5vh;
    border: 1px solid rgba(var(--color-blk), 0.2);
    font-size: 0.8rem;
    padding: 0rem 1rem;
    margin-bottom: 4%;
  }
`

export const CheckStyled = styled.input`
  width: 18%;
  height: 1.2rem;
  border: 1px solid rgba(var(--color-blk), 0.2);
  font-size: 1rem;
`

export const ActionButton0 = styled.a`
  cursor: pointer;
  padding: 1rem;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid rgba(var(--color-strk), 1);
  background: rgba(var(--color-strk), 0.4);
  box-shadow: 0 10px 20px -10px rgba(var(--color-wht), 0.3);
  transition: all var(--speed);
  width: auto;
  height: auto;
  text-decoration: none;

  &:hover {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: #ff7571;
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  &:focus {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: #ff7571;
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-right: 2rem;
    font-size: 0.8rem;
    padding: 0.6rem;
  }
`

export const ActionButton1 = styled(Link)`
  padding: 1rem;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid rgba(var(--color-strk), 1);
  background: rgba(var(--color-strk), 0.4);
  box-shadow: 0 10px 20px -10px rgba(var(--color-wht), 0.3);
  transition: all var(--speed);
  width: auto;
  height: auto;
  text-decoration: none;

  &:hover {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: #ff7571;
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  &:focus {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: #ff7571;
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-right: 2rem;
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`

export const ActionButton2 = styled.button`
  padding: 1rem;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid rgba(var(--color-strk), 1);
  background: rgba(var(--color-strk), 0.4);
  box-shadow: 0 10px 20px -10px rgba(var(--color-wht), 0.3);
  transition: all var(--speed);
  width: auto;
  height: auto;
  text-decoration: none;

  &:hover {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: #ff7571;
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  &:focus {
    border: 1px solid rgba(var(--color-wht), 0.7);
    background: #ff7571;
    box-shadow: 0 15px 20px -10px rgba(var(--color-wht), 0.5);
    transform: translateY(-5px);
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-right: 2rem;
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`

export const ContextWrapper = styled.div`
  width: 60%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 4rem 2rem 0rem;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem 1rem 0rem 0rem;
  }
`

export const ContextWrapperRow = styled.div`
  width: 60%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 4rem 0rem 0rem;

  width: 100%;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 1rem 0rem 0rem;
  }
`

export const ContextWrapperGrid = styled.div`
  width: 60%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 4rem 2rem 0rem;

  padding: 0 4%;
  width: 50%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
  }
`

export const ContentImageWrapperRow = styled.div`
  width: 40%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;

  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.small} {
  }
`

export const ContentImageWrapper = styled.div`
  width: 40%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.small} {
  }
`
