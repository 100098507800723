import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Logo from "../../assets/LOGO.png"
import {
  FooterWrapper,
  BannerTitleText,
  NotImpSubHeader0,
} from "../UI/PublicUI"
import "./styles.css"
import { useWindowSize } from "../../utils/useWindowSize"

export const Section1 = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
`
export const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 6%;
`
export const Section3 = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    padding-bottom: 10%;
  }
`
export const Section4 = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`
export const SubTextFooter = styled.a`
  text-decoration: none;
  color: rgba(var(--color-wht), 1);
  font-family: Poppins;
  font-size: 0.8rem;
  text-transform: initial;
  font-weight: 600;
  line-height: 2rem;
  margin: 0% 4% 6% 0%;

  transition: all var(--speed);

  &:hover {
    color: #ff7571;
    transform: translateY(-3px);
  }
`
export const Section5 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: auto;
`

const Footer = () => {
  const size = useWindowSize()

  return (
    <FooterWrapper className="footer_pattern">
      <Section3>
        <BannerTitleText
          style={{
            alignSelf: "unset",
            margin: "0%",
          }}
        >
          Creating World With No Boundaries.
        </BannerTitleText>
      </Section3>
      <Section5>
        {size.windowSize.width < 580 ? null : (
          <Section2>
            {/* <NotImpSubHeader0>About Us</NotImpSubHeader0> */}
            {/* <NotImpSubHeader0>Our Team</NotImpSubHeader0> */}
            {/* <NotImpSubHeader0>Terms Of Use</NotImpSubHeader0> */}
            {/* <NotImpSubHeader0>Privacy Policy</NotImpSubHeader0> */}
            <NotImpSubHeader0 href="mailto:info@polytopenetworks.com">
              Work With Us
            </NotImpSubHeader0>
          </Section2>
        )}

        <Section4>
          <SubTextFooter
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/polytopenetworks/"
          >
            Ln
          </SubTextFooter>
          <SubTextFooter
            target="_blank"
            rel="noreferrer"
            href="https://github.com/polytopenetworks"
          >
            Gh
          </SubTextFooter>
          <SubTextFooter
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/polytopenet"
          >
            Tw
          </SubTextFooter>
          <SubTextFooter
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/polytopenetworks"
          >
            Fb
          </SubTextFooter>
          <SubTextFooter
            style={{
              marginRight: "0%",
            }}
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/polytopenetworks/"
          >
            Ig
          </SubTextFooter>
        </Section4>
        <div
          style={{
            width: "auto",
            height: "auto",
          }}
        >
          <NotImpSubHeader0
            style={{
              fontSize: "0.6rem",
              fontWeight: "300",
            }}
          >
            © 2021 Polytope Networks Pvt Ltd | All rights reserved |
            info@polytopenetworks.com
          </NotImpSubHeader0>
        </div>
      </Section5>
    </FooterWrapper>
  )
}

export default Footer
