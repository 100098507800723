import {
  BannerSecImg,
  ColoredMarker,
  ContentWrapper,
  NotImpSubHeader,
  SectionImageWrapper,
  SectionWrapper,
  SubHeader,
} from "../UI/PublicUI"
import B2 from "../../assets/images/B2.jpg"

export const Challenge = ({ id }) => {
  return (
    <SectionWrapper id={id}>
      <SectionImageWrapper>
        <BannerSecImg src={B2} alt="" />
      </SectionImageWrapper>
      <ContentWrapper>
        <SubHeader>Why?</SubHeader>
        <SubHeader>
          <ColoredMarker>Polytope Networks</ColoredMarker>
        </SubHeader>
        <NotImpSubHeader>
          Polytope Networks helps protect existing operations against the rising
          tide of cyber attacks by using public and private distributed systems
          and enables the future of highly-connected networks within machines
          and cloud with high security and ease of industrial connection
          topologies.
          <br />
          <br />
          We make systems interact with each other efficiently with high
          security and independent of there connection topologies.
        </NotImpSubHeader>
      </ContentWrapper>
    </SectionWrapper>
  )
}
