import {
  ColoredMarker,
  ContentWrapper,
  NotImpSubHeader,
  SectionImageWrapper,
  SectionWrapper2,
  SubHeader,
  SectionWrapper1,
} from "../UI/PublicUI"

export const Description = ({ id }) => {
  return (
    <SectionWrapper1 id={id}>
      <ContentWrapper large>
        <SubHeader>What we do,</SubHeader>
        <SubHeader>
          <ColoredMarker>
            We develop customised blockchain systems, services and Smart
            Contracts by boosting efficiency, heighten security.
            <br />
            <br />
            Create transparency in your company, taking the full reponsibilty
            for the development of your applications as well.
          </ColoredMarker>
        </SubHeader>
        <NotImpSubHeader>
          A system with nothing to support it doesn’t make sense.
          <br />
          We create supporting systems like websites and applications or
          integrate your new Blockchain into an existing system or new system.
        </NotImpSubHeader>
      </ContentWrapper>
    </SectionWrapper1>
  )
}
