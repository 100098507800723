import {
  BannerImageWrapper,
  BannerSecImg,
  CardsRow,
  ColoredMarker,
  ContentWrapper,
  FeatureCard,
  FeatureCardIcon,
  NotImpSubHeader,
  NotImpSubHeader2,
  SectionImageWrapper,
  SectionWrapper5,
  SubHeader,
  ContextWrapper,
  ContentImageWrapper,
  SubHeader2,
  NotImpSubHeader02,
} from "../UI/PublicUI"
import B3 from "../../assets/images/B3.jpg"
import { useWindowSize } from "../../utils/useWindowSize"
import { FeaturesData } from "../../pages/prospect/Prospect"

export const Solutions = ({ id }) => {
  const size = useWindowSize()

  return (
    <SectionWrapper5 id={id}>
      <ContentWrapper large>
        <SubHeader>The solutions,</SubHeader>
        <SubHeader>
          <ColoredMarker>We are working on -</ColoredMarker>
        </SubHeader>
        <CardsRow>
          {FeaturesData.map((item) => (
            <FeatureCard large key={item?.id}>
              <ContextWrapper>
                <SubHeader2>{item?.feature}</SubHeader2>
                {item?.desc?.map((itm) => (
                  <>
                    {itm?.d1 ? (
                      <NotImpSubHeader02>{itm?.d1}</NotImpSubHeader02>
                    ) : null}
                    {itm?.d2?.i1 ? (
                      <NotImpSubHeader2>{itm?.d2?.i1}</NotImpSubHeader2>
                    ) : null}
                    {itm?.d2?.i2 ? (
                      <NotImpSubHeader2>{itm?.d2?.i2}</NotImpSubHeader2>
                    ) : null}
                    {itm?.d2?.i3 ? (
                      <NotImpSubHeader2>{itm?.d2?.i3}</NotImpSubHeader2>
                    ) : null}
                    {itm?.d3?.i1 ? (
                      <NotImpSubHeader2>{itm?.d3?.i1}</NotImpSubHeader2>
                    ) : null}
                    {itm?.d3?.i2 ? (
                      <NotImpSubHeader2>{itm?.d3?.i2}</NotImpSubHeader2>
                    ) : null}
                    {itm?.d3?.i3 ? (
                      <NotImpSubHeader2>{itm?.d3?.i3}</NotImpSubHeader2>
                    ) : null}
                    {itm?.d4?.i1 ? (
                      <NotImpSubHeader2>{itm?.d4?.i1}</NotImpSubHeader2>
                    ) : null}
                    {itm?.d4?.i2 ? (
                      <NotImpSubHeader2>{itm?.d4?.i2}</NotImpSubHeader2>
                    ) : null}
                    {itm?.d4?.i3 ? (
                      <NotImpSubHeader2>{itm?.d4?.i3}</NotImpSubHeader2>
                    ) : null}
                  </>
                ))}
              </ContextWrapper>
              <ContentImageWrapper>
                <FeatureCardIcon src={item?.src} alt="" />
              </ContentImageWrapper>
            </FeatureCard>
          ))}
        </CardsRow>
      </ContentWrapper>
      <BannerImageWrapper
        style={
          size.windowSize.width < 580
            ? {
                top: "unset",
                right: 0,
                marginTop: "-25%",
                width: "100%",
                height: "40vh",
              }
            : {
                top: "unset",
                right: 0,
                marginTop: "-25%",
              }
        }
      >
        <BannerSecImg src={B3} alt="" />
      </BannerImageWrapper>
    </SectionWrapper5>
  )
}
