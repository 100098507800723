import React from "react"
import {
  BannerImageWrapper,
  BannerSecImg,
  CardsRow,
  ColoredMarker,
  ContentWrapper,
  FeatureCardRow,
  FeatureCardIconRow,
  NotImpSubHeader,
  NotImpSubHeader2,
  SectionImageWrapper,
  SectionWrapper6,
  SubHeader,
  ContextWrapperRow,
  ContentImageWrapperRow,
  SubHeader2,
  ButtonWrapper,
  Container,
  IntroSectionWrapper,
  NormalMarker,
  SecActionButton,
  BannerTitleText,
  BannerImageWrapper2,
  ActionButton1,
  ActionButton0,
  ScrollLinks2,
  ActionButton,
  BannerImageWrapperMain,
} from "../../components/UI/PublicUI"
import B9 from "../../assets/images/B9.jpg"
import { useWindowSize } from "../../utils/useWindowSize"
import { SubFooter } from "../../components/Sections/SubFooter"

import analysis_1 from "../../assets/images/analysis_1.jpg"
import analysis_2 from "../../assets/images/analysis_2.jpg"
import analysis_3 from "../../assets/images/analysis_3.jpg"
import analysis_4 from "../../assets/images/analysis_4.jpg"
import analysis_5 from "../../assets/images/analysis_5.jpg"

const FeaturesData = [
  {
    id: 1,

    feature: "Decentralized Exchange",
    desc1:
      "It is a trading market that executes transactions automatically on a blockchain, rather than a centralized exchange that relies on a trusted third party, typically represented by cryptocurrency exchanges. Although centralized exchanges are easy to use and are available of various types of transactions such as reserved transactions and margin transactions, users have to completely trust the exchange and anonymous transactions are difficult to take place since users have to sign up before use. In particular, users are the ones who suffer from the damage when accidents such as hacking break out, as in the case of the Mt.Gox incident15. Decentralized Exchange on the other hand, enables automated transactions without the need to trust a particular exchange, supports completely anonymous transactions, and is free from problems such as server breakdown and hacking.",
    src: analysis_1,
  },
  {
    id: 2,

    feature: "Blockchain and Real Estate",
    desc1:
      "Blockchain commands trust and security as a decentralized technology. Information stored in the blockchain is accessible to all peers on the network, making data transparent and immutable. One only has to go back to the housing bubble crash in 2008 to see how greed and the lack of transparency in the part of institutions can have catastrophic consequences. A decentralized exchange has trust built into the system. Since information can be verifiable to peers, buyers and sellers can have more confidence in conducting transactions. Fraud attempts would also be lessened.",
    desc2:
      "The transparency associated with a decentralized network can also trim down costs associated with real estate transactions. Beyond the savings made by cutting out intermediaries’ professional fees and commissions, there are other costs such as inspections costs, registration fees, loan fees, and taxes associated with real estate. These costs even vary depending on the territory that has jurisdiction. Like intermediaries, these can be reduced or even eliminated from the equation as platforms automate these processes and make them part of the system. Through blockchain technology, it is possible that more people will be able to access the market where transactions can be made more transparent, secure, and equitable. Real estate transactions may eventually become truly peer-to-peer activities with blockchain-powered platforms doing most of the work.",
    src: analysis_2,
  },
  {
    id: 3,

    feature: "Blockchain and Digital Identity",
    desc1:
      "The traditional identity systems of today are fragmented, insecure, and exclusive. Blockchain enables more secure management and storage of digital identities by providing unified, interoperable, and tamper-proof infrastructure with key benefits to users.",
    src: analysis_3,
  },
  {
    id: 4,

    feature: "Blockchain and KYC",
    desc1:
      "KYC may be a manual, time-consuming, and redundant across institutions. Sharing KYC information on Blockchain would enable financial institutions to deliver better compliance outcomes, increase efficiency, and improve customer experience.",
    src: analysis_4,
  },
  {
    id: 5,

    feature: "Blockchain and Supply Chain",
    desc1:
      "Using blockchain in the supply chain has the potential to improve supply chain transparency and traceability as well as reduce administrative costs. Blockchain can enable more transparent and accurate end - to - end tracking in the supply chain: Organizations can digitize physical assets and create a decentralized immutable record of all transactions, making it possible to track assets from production to delivery or use by end user.This increased supply chain transparency provides more visibility to both businesses and consumers.",
    desc2:
      "Furthermore, businesses can maintain more control over all parties within a respective supply chain with access to the same information, potentially reducing communication or transfer data errors. Less time can be spent validating data and more can be spent on delivering goods and services—either improving quality, reducing cost, or both.",
    src: analysis_5,
  },
]

const Analysis = ({ id }) => {
  const size = useWindowSize()

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <>
      <Container start>
        <IntroSectionWrapper>
          <BannerTitleText>The /FUTURE is shifting</BannerTitleText>
          <br />
          <br />
          <NotImpSubHeader
            style={{
              color: "rgba(var(--color-wht),0.8)",
            }}
          >
            Here's our extension and ease to deliver our analysis on the
            targeted case studies of the FUTURE market in need.
          </NotImpSubHeader>
        </IntroSectionWrapper>
        <BannerImageWrapperMain>
          <BannerSecImg src={B9} alt="" />
        </BannerImageWrapperMain>
        {/* <SectionImageWrapper></SectionImageWrapper> */}
      </Container>

      <SectionWrapper6 id={id}>
        <ContentWrapper large>
          <NotImpSubHeader>
            A wide variety of communities are beginning to be formed around the
            world for various business purposes and relationships in the field
            of finance, public service, logistics, real estate, healthcare, IoT,
            energy, manufacture, e-commerce, and much more. With the development
            and spread of blockchain technology, the number of these communities
            is expected to accelerate. In such an environment, many tasks are
            handled through Smart Contracts, and the role of centralized
            agencies and intermediaries is expected to either shrink or
            disappear.
            <br />
            <br />
            In general, most communities begin with the goal of improving the
            work efficiency among the members, but in many cases, they evolve
            naturally in the direction of increasing interactions with the
            members of other communities. In this case, instead of handling
            external affairs through a separate centralized institution, Smart
            Contracts can reliably liaise the relationships.
          </NotImpSubHeader>

          <SubHeader>The studies,</SubHeader>
          <SubHeader>
            <ColoredMarker>We came forward with -</ColoredMarker>
          </SubHeader>
          <CardsRow>
            {FeaturesData.map((item) => (
              <FeatureCardRow large key={item?.id}>
                <ContextWrapperRow>
                  <SubHeader2>{item?.feature}</SubHeader2>
                  <NotImpSubHeader2>{item?.desc1}</NotImpSubHeader2>
                  <NotImpSubHeader2>{item?.desc2}</NotImpSubHeader2>
                </ContextWrapperRow>
                <ContentImageWrapperRow>
                  <FeatureCardIconRow src={item?.src} alt="" />
                </ContentImageWrapperRow>
              </FeatureCardRow>
            ))}
          </CardsRow>

          <ButtonWrapper>
            <ActionButton
              to="/about"
              style={{
                marginRight: "2rem",
              }}
            >
              About us
            </ActionButton>
            <ActionButton1 to="/prospect">Our prospect</ActionButton1>
          </ButtonWrapper>
        </ContentWrapper>
        {/* <BannerImageWrapper
          style={
            size.windowSize.width < 580
              ? {
                  top: "unset",
                  right: 0,
                  marginTop: "-25%",
                  width: "100%",
                  height: "40vh",
                }
              : {
                  top: "unset",
                  right: 0,
                  marginTop: "-25%",
                }
          }
        >
          <BannerSecImg src={B3} alt="" />
        </BannerImageWrapper> */}
      </SectionWrapper6>

      <SubFooter />
    </>
  )
}

export default Analysis
