import {
  ActionButton,
  ButtonWrapper,
  Container,
  IntroSectionWrapper,
  NormalMarker,
  NotImpSubHeader,
  SecActionButton,
  SubHeader,
  BannerTitleText,
  BannerImageWrapper,
  BannerImageWrapper2,
  BannerSecImg,
  SectionImageWrapper,
  ActionButton2,
  ActionButton0,
  ScrollLinks2,
  BannerImageWrapperMain,
} from "../UI/PublicUI"
import B1 from "../../assets/images/B1.jpg"

export const HomeBanner = () => {
  return (
    <Container start>
      <IntroSectionWrapper>
        <BannerTitleText>Creating World With No Boundaries.</BannerTitleText>
        <br />
        <NotImpSubHeader
          style={{
            color: "rgba(var(--color-wht),0.8)",
          }}
        >
          Boost your efficiency, security and speed with Us.
          <br />
          <br />
          Zero trust identity, data security and local or remote access for
          physical and digital operations spanning IOT, IT and cloud.
        </NotImpSubHeader>

        <ButtonWrapper>
          <ScrollLinks2
            // key={i.id}
            activeclassName="active"
            // to={i.link}
            to={"challenge"}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            style={{
              marginRight: "2rem",
            }}
          >
            Explore more
          </ScrollLinks2>

          <ActionButton0 href="mailto:info@polytopenetworks.com">
            Get in touch
          </ActionButton0>
        </ButtonWrapper>
      </IntroSectionWrapper>
      <BannerImageWrapperMain>
        <BannerSecImg src={B1} alt="" />
      </BannerImageWrapperMain>
      {/* <SectionImageWrapper></SectionImageWrapper> */}
    </Container>
  )
}
