const theme = {
  colors: {
    pri: "9, 87, 195",
    strk: "252, 116, 152",
    wht: "255, 255, 255",
    blk: "0, 0, 0",
  },

  size: {
    smallest: "25em", //275px #
    smaller: "31.25em", //500px
    small: "37.5em", //600px #
    medium: "56.25em", //900px #
    large: "80em", //1300px
    larger: "90em", //1300px
    largest: "97em", //1500px #
    extra_large: "160em", //4k
  },

  mediaQueries: {
    smallest: "only screen and (max-width: 25em)",
    smaller: "only screen and (max-width: 31.25em)",
    small: "only screen and (max-width: 37.5em)",
    medium: "only screen and (max-width: 56.25em)",
    large: "only screen and (max-width: 80em)",
    larger: "only screen and (max-width: 90em)",
    largest: "only screen and (max-width: 97em)",
    extra_large: "only screen and (max-width: 160em)", // Yeah I know 🤣!!
  },
}

export default theme
