import {
  ActionButton,
  ButtonWrapper,
  CardWrapperTextSection,
  ColoredMarker,
  GetStartedCard,
  GetStartedWrapper,
  NotImpSubHeader,
  SecActionButton,
  SubHeaderFooter,
  Title,
  SubHeaderFooter2,
} from "../UI/PublicUI"

export const SubFooter = () => {
  return (
    <CardWrapperTextSection>
      <GetStartedCard href="mailto:info@polytopenetworks.com">
        <SubHeaderFooter>Get in touch</SubHeaderFooter>
        <SubHeaderFooter2>or Just say Hi!</SubHeaderFooter2>
      </GetStartedCard>
    </CardWrapperTextSection>
  )
}
