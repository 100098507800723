import React from "react"
import {
  BannerImageWrapper,
  BannerSecImg,
  CardsRowGrid,
  ColoredMarker,
  ContentWrapper,
  FeatureCardGrid,
  FeatureCardIcon,
  NotImpSubHeader,
  NotImpSubHeader2,
  SectionImageWrapper,
  SectionWrapper5,
  SubHeader,
  ContextWrapperGrid,
  ContentImageWrapper,
  SubHeader2,
  ButtonWrapper,
  Container,
  IntroSectionWrapper,
  NormalMarker,
  SecActionButton,
  BannerTitleText,
  BannerImageWrapper2,
  ActionButton1,
  ActionButton0,
  ScrollLinks2,
  ActionButton,
  BannerImageWrapperMain,
} from "../../components/UI/PublicUI"
import { useWindowSize } from "../../utils/useWindowSize"
import { SubFooter } from "../../components/Sections/SubFooter"

import Naveen from "../../assets/images/naveen.jpg"
import Shubham from "../../assets/images/shubham.jpg"
import Debo from "../../assets/images/debo.png"
import Subho from "../../assets/images/subho.png"

const TeamData = [
  {
    id: 1,
    img: Naveen,
    name: "Naveen Kumar",
    position: "Co-Founder, Shareholder",
  },
  {
    id: 2,
    img: Shubham,
    name: "Shubham S Sapkal",
    position: "Co-Founder, Director, COO",
  },
  // {
  //   id: 3,
  //   img: Debo,
  //   name: "Debo",
  //   position: "Co-Founder, Director",
  // },
  // {
  //   id: 4,
  //   img: Subho,
  //   name: "Subho",
  //   position: "Co-Founder, Director",
  // },
]

const Team = ({ id }) => {
  const size = useWindowSize()

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <>
      <Container start>
        <IntroSectionWrapper>
          <BannerTitleText>Our Team</BannerTitleText>
          <br />
          <br />
          <NotImpSubHeader
            style={{
              color: "rgba(var(--color-wht),0.8)",
            }}
          >
            We’re about people,
            <br />
            <br />
            Our team firmly believes to be a well-connected and cohesive
            community. We push each other to get better; we could never be as
            good alone as we are together.
          </NotImpSubHeader>

          <ButtonWrapper>
            <ActionButton
              to="/about"
              style={{
                marginRight: "2rem",
              }}
            >
              About us
            </ActionButton>
          </ButtonWrapper>
        </IntroSectionWrapper>
        <BannerImageWrapperMain>
          {/* <BannerSecImg src={B10} alt="" /> */}
        </BannerImageWrapperMain>
        {/* <SectionImageWrapper></SectionImageWrapper> */}
      </Container>

      <SectionWrapper5 id={id}>
        <ContentWrapper large>
          <SubHeader>Hello,</SubHeader>
          <SubHeader>
            <ColoredMarker>
              We are a team of talented individuals, putting best results to the
              table with the teamwork.
            </ColoredMarker>
          </SubHeader>
          <CardsRowGrid>
            {TeamData.map((item) => (
              <FeatureCardGrid large key={item?.id}>
                <ContextWrapperGrid>
                  <SubHeader2>{item?.name}</SubHeader2>
                  <NotImpSubHeader2>{item?.position}</NotImpSubHeader2>
                </ContextWrapperGrid>
                <ContentImageWrapper>
                  <FeatureCardIcon src={item?.img} alt="" />
                </ContentImageWrapper>
              </FeatureCardGrid>
            ))}
          </CardsRowGrid>
        </ContentWrapper>
      </SectionWrapper5>

      <SubFooter />
    </>
  )
}

export default Team
