import PropTypes from "prop-types"
import styled from "styled-components"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(var(--color-wht), 1);
  overflow-x: hidden;

  @media ${({ theme }) => theme.mediaQueries.small} {
    height: auto;
  }
`

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <Header />
      {children}
      <Footer />
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
