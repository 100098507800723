import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  * {
    outline: none;
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }

  *::-webkit-scrollbar {
    width: 0.3vw;
    height: 0.3vw;
  }
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background: rgba(var(--color-blk),0.2);
    border-radius: 5rem;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
    width: 100%;
    line-height: 1.2;
    font-size: 100%;
    font-weight: 400;

    --color-pri: ${(props) => props.theme.colors.pri};
    --color-strk: ${(props) => props.theme.colors.strk};
    --color-wht: ${(props) => props.theme.colors.wht};
    --color-blk: ${(props) => props.theme.colors.blk};

    --speed: 480ms;
  }

  body {
    user-select: none;
    background-color: rgba(var(--color-wht),1);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;
    overflow: visible;
    scroll-behavior: smooth;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 1rem 0;
  }

  p {
    margin: 0 0 0.5rem 0;
  }

  form,
  input,
  textarea,
  select,
  a {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  button {
    outline: none;
    cursor: pointer;
  }
`
