import React from "react"
import { HomeBanner } from "../../components/Sections/HomeBanner"
import { Challenge } from "../../components/Sections/Challenge"
import { Description } from "../../components/Sections/Description"
import { Solutions } from "../../components/Sections/Solutions"
import { AboutUs } from "../../components/Sections/AboutUs"
import { SubFooter } from "../../components/Sections/SubFooter"

const Home = () => {
  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <>
      <HomeBanner />
      <Description />
      <Challenge id="challenge" />
      <Solutions />
      <SubFooter />
    </>
  )
}

export default Home
