import React from "react"
import {
  BannerImageWrapper,
  BannerSecImg,
  CardsRow,
  ColoredMarker,
  ContentWrapper,
  FeatureCard,
  FeatureCardIcon,
  NotImpSubHeader,
  NotImpSubHeader2,
  SectionImageWrapper,
  SectionWrapper5,
  SubHeader,
  ContextWrapper,
  ContentImageWrapper,
  SubHeader2,
  ButtonWrapper,
  Container,
  IntroSectionWrapper,
  NormalMarker,
  SecActionButton,
  BannerTitleText,
  BannerImageWrapper2,
  ActionButton1,
  ActionButton0,
  ScrollLinks2,
  ActionButton,
  BannerImageWrapperMain,
  NotImpSubHeader02,
} from "../../components/UI/PublicUI"
import B3 from "../../assets/images/B3.jpg"
import B8 from "../../assets/images/B8.jpg"
import { useWindowSize } from "../../utils/useWindowSize"
import { SubFooter } from "../../components/Sections/SubFooter"
import IMG_7896 from "../../assets/images/IMG_7896.jpg"
import IMG_7897 from "../../assets/images/IMG_7897.png"
import IMG_7898 from "../../assets/images/IMG_7898.png"

export const FeaturesData = [
  {
    id: 1,
    feature:
      "Supply Chain Solution - SHUBHARTHA The marketplace for Supply Chain",
    desc: [
      {
        d1:
          "-  Intelligent visibility due to Distributed Ledger Technology or Enterprise Blockchain",
        d2: {
          i1:
            "-  Structural Visibility, Network Mapping, Classic risk management, Modeling",
        },
        d3: {
          i1: "-  Dynamic Visibility, Monitoring, Predicting, Prescribing",
        },
      },
      {
        d1: "-  Smart Assistance for cost and time efficient shipment",
        d2: {
          i1:
            "-  Market Forecasting - The system will notify the demand for the goods to agro farms, local and retail markets with minimum and maximum demand cost and supply cost.",
        },
        d3: {
          i1:
            "-  Inventory Management - The system will provide real-time inventory data with a low cost solution for the supplier with the inventory management companies earning substantial profit without disrupting the shipment",
        },
        d4: {
          i1:
            "-  Selection of Supplier, vendors, investors, shipment companies via land, via air, via sea, inventory partners, packaging partners, etc",
        },
      },
    ],
    src: IMG_7897,
  },
  {
    id: 2,
    feature: "Polytope Networks CBDC",
    desc: [
      {
        d1:
          "-  Central bank digital currencies (CBDCs) are a form of digital currency issued by a country's central bank. They are similar to cryptocurrencies, except that their value is fixed by the central bank and equivalent to the country's fiat currency.",
        d2: {
          i1: "-  Security and Transparency in a single entity",
          i2:
            "-  Reduced transaction costs, transparency and improved security by eliminating intermediaries, benefiting individuals and businesses",
        },
      },
      {
        d1:
          "-  Individual user’s and small business’s accessibility to banking",
        d2: {
          i1:
            "-  Increased financial inclusion, empowering the unbanked. CBDC allows anyone with a mobile phone to access and participate in the digital economy",
        },
      },
      {
        d1: "-  Eliminating Centralized controlled Banking Sectors",
        d2: {
          i1:
            "-  Immutable transaction records on a distributed ledger ensure transparency. Streamlines government disbursements and welfare programs",
        },
      },
    ],
    src: IMG_7896,
  },
  {
    id: 3,
    feature: "Vehicle to Everything Communications (V2X)",
    desc: [
      {
        d1:
          "-  The concept of a “connected car” is not new to the automotive industry",
        d2: {
          i1:
            "-  The technology to make it possible (as well as the necessary communication standards) were not available until a few years ago",
          i2:
            "-  You can think of V2X as the parent category of a broader set of communication technologies needed to achieve the goal of connecting vehicles with the world surrounding them. Literally.",
        },
      },
    ],
    src: IMG_7898,
  },
]

const Prospect = ({ id }) => {
  const size = useWindowSize()

  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <>
      <Container start>
        <IntroSectionWrapper>
          <BannerTitleText>The Possibilities,</BannerTitleText>
          <br />
          <br />
          <NotImpSubHeader
            style={{
              color: "rgba(var(--color-wht),0.8)",
            }}
          >
            We belive in,
            <br />
            <br />
            In creating 'The Possibilities' to be come-alive, convenient and
            lucrative for our clients. The purpose is to redefine the whole
            perspective of decentralized systems experience with the help of
            different platforms.
          </NotImpSubHeader>
        </IntroSectionWrapper>
        <BannerImageWrapperMain>
          <BannerSecImg src={B8} alt="" />
        </BannerImageWrapperMain>
        {/* <SectionImageWrapper></SectionImageWrapper> */}
      </Container>

      <SectionWrapper5 id={id}>
        <ContentWrapper large>
          <SubHeader>The solutions,</SubHeader>
          <SubHeader>
            <ColoredMarker>We are working on -</ColoredMarker>
          </SubHeader>
          <CardsRow>
            {FeaturesData.map((item) => (
              <FeatureCard large key={item?.id}>
                <ContextWrapper>
                  <SubHeader2>{item?.feature}</SubHeader2>
                  {item?.desc?.map((itm) => (
                    <>
                      {itm?.d1 ? (
                        <NotImpSubHeader02>{itm?.d1}</NotImpSubHeader02>
                      ) : null}
                      {itm?.d2?.i1 ? (
                        <NotImpSubHeader2>{itm?.d2?.i1}</NotImpSubHeader2>
                      ) : null}
                      {itm?.d2?.i2 ? (
                        <NotImpSubHeader2>{itm?.d2?.i2}</NotImpSubHeader2>
                      ) : null}
                      {itm?.d2?.i3 ? (
                        <NotImpSubHeader2>{itm?.d2?.i3}</NotImpSubHeader2>
                      ) : null}
                      {itm?.d3?.i1 ? (
                        <NotImpSubHeader2>{itm?.d3?.i1}</NotImpSubHeader2>
                      ) : null}
                      {itm?.d3?.i2 ? (
                        <NotImpSubHeader2>{itm?.d3?.i2}</NotImpSubHeader2>
                      ) : null}
                      {itm?.d3?.i3 ? (
                        <NotImpSubHeader2>{itm?.d3?.i3}</NotImpSubHeader2>
                      ) : null}
                      {itm?.d4?.i1 ? (
                        <NotImpSubHeader2>{itm?.d4?.i1}</NotImpSubHeader2>
                      ) : null}
                      {itm?.d4?.i2 ? (
                        <NotImpSubHeader2>{itm?.d4?.i2}</NotImpSubHeader2>
                      ) : null}
                      {itm?.d4?.i3 ? (
                        <NotImpSubHeader2>{itm?.d4?.i3}</NotImpSubHeader2>
                      ) : null}
                    </>
                  ))}
                </ContextWrapper>
                <ContentImageWrapper>
                  <FeatureCardIcon src={item?.src} alt="" />
                </ContentImageWrapper>
              </FeatureCard>
            ))}
          </CardsRow>

          <ButtonWrapper>
            <ActionButton
              to="/about"
              style={{
                marginRight: "2rem",
              }}
            >
              About us
            </ActionButton>
            <ActionButton1 to="/analysis">Our Analysis</ActionButton1>
          </ButtonWrapper>
        </ContentWrapper>
        <BannerImageWrapper
          style={
            size.windowSize.width < 580
              ? {
                  top: "unset",
                  right: 0,
                  marginTop: "-25%",
                  width: "100%",
                  height: "40vh",
                }
              : {
                  top: "unset",
                  right: 0,
                  marginTop: "-25%",
                }
          }
        >
          <BannerSecImg src={B3} alt="" />
        </BannerImageWrapper>
      </SectionWrapper5>

      <SubFooter />
    </>
  )
}

export default Prospect
