import {
  BannerImageWrapper,
  BannerSecImg,
  CardsRow,
  ColoredMarker,
  ContentWrapper,
  FeatureCard,
  FeatureCardIcon,
  NotImpSubHeader,
  NotImpSubHeader2,
  SectionImageWrapper,
  SectionWrapper,
  SectionWrapper2,
  SubHeader,
  SubHeader2,
  SectionWrapper1,
} from "../UI/PublicUI"
import B4 from "../../assets/images/B4.jpg"
import B5 from "../../assets/images/B5.jpg"

export const AboutUs = ({ id }) => {
  return (
    <>
      <SectionWrapper1 id={id}>
        <ContentWrapper>
          <SubHeader></SubHeader>
          <SubHeader>
            <ColoredMarker>We Polytope Networks,</ColoredMarker>
          </SubHeader>

          <NotImpSubHeader>
            A global technology company established exclusively to cater the
            needs of software solutions. With a view to endowing our clients
            with the optimum IT solutions in terms of Blockchain Development,
            Web Development & Mobile Development.
            <br />
            <br />
            Toiling along a long path, today we are a well-known name in IT
            Development Industry. Although the unit is recently established, it
            is backed by most experienced and efficient team of professionals
            who have expertise and also several years of experience.
            <br />
            <br />
            As well-established company we always strive for excellence. Our
            policy is to keep pace with the changing needs of the customers in
            relation to Quality & Advancing Essentials. We are a leading and
            globally acknowledged IT solution provider and currently at the
            forefront of offshore software development to serve the people
            across the world.
          </NotImpSubHeader>
        </ContentWrapper>

        <SectionImageWrapper>
          <BannerSecImg src={B4} alt="" />
        </SectionImageWrapper>
      </SectionWrapper1>

      <SectionWrapper
        style={{
          height: "auto",
        }}
        id={id}
      >
        <SectionImageWrapper>
          <BannerSecImg src={B5} alt="" />
        </SectionImageWrapper>

        <ContentWrapper>
          <SubHeader>We have the</SubHeader>
          <SubHeader>
            <ColoredMarker>Expertise Knowledge of,</ColoredMarker>
          </SubHeader>

          <NotImpSubHeader2>
            <ul>
              <li>Blockchain Development</li> <li>AI Engine Development</li>{" "}
              <li>Distributed Systems Development(Hyperledger/Corda)</li>{" "}
              <li>NLP</li> <li>DApp</li> <li>UI/UX Development</li>{" "}
              <li>Web Development</li> <li>Web Designing</li>{" "}
              <li>E-Commerce Solutions</li> <li>Search Engine Optimization</li>{" "}
              <li>Mobile Application Development i.e. Android and IOS</li>{" "}
              <li>Business Software Services</li>
            </ul>
          </NotImpSubHeader2>
          <NotImpSubHeader>
            We develop robust platforms, while also delivering maintenance and
            security on a robust architecture.
            <br />
            <br />
            Help manage diverse and complex applications at lower cost and
            enable rapid response to requests for new functionality and
            requirements. Our iterative framework helps you define, refine and
            execute against your holistic mobile strategy.
            <br />
            Our experts work with you to evaluate your current mobile/web
            landscape and co-create mobile solutions in the dimensions that
            matter most.
            <br />
            <br />
            We are glad to serve you!
          </NotImpSubHeader>
        </ContentWrapper>
      </SectionWrapper>
    </>
  )
}
